import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Dialog from "@ui-kit/Dialog";
import Button from "@ui-kit/Button";
import Typography from "@ui-kit/Typography";
import { useCss } from "./styles";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { Models } from "@services/api";
import { Spacer } from "@ui-kit/Spacer";
import SchoolOutlinedIcon from "@material-ui/icons/SchoolOutlined";
import { Tier } from "../Tier";

const AboutTierDialog: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const css = useCss();

  const close = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  return (
    <Dialog
      open
      onClose={close}
      data-test="create-facility-form"
      title="About school tiers"
      tier={facility.subscriptionTier}
    >
      <div css={css.root}>
        <div css={css.tiersContainer}>
          <Tier
            tier={Models.SubscriptionTier.Community}
            active={
              facility.subscriptionTier === Models.SubscriptionTier.Community
            }
            styles={{
              root: css.tier,
            }}
          />
          <Tier
            tier={Models.SubscriptionTier.Pro}
            active={facility.subscriptionTier === Models.SubscriptionTier.Pro}
            styles={{
              root: css.tier,
            }}
          />
          <Tier
            tier={Models.SubscriptionTier.Ivy}
            active={facility.subscriptionTier === Models.SubscriptionTier.Ivy}
            styles={{
              root: css.tier,
            }}
          />
        </div>

        <Spacer size="small" />

        <Typography paragraph>
          All schools start at the Base Tier level.
        </Typography>

        <Typography paragraph>
          Higher performing facilities graduate to our premium Pro Tier, while
          top performing schools join the premier Ivy Tier.
        </Typography>

        <Typography gap>
          School tier reflects parent interest and engagement with the facility,
          as measured by submitted reviews, likes, shares, as well as school
          tour and enrollment requests.
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          css={css.button}
          onClick={close}
        >
          Close
        </Button>
      </div>
    </Dialog>
  );
};

export default AboutTierDialog;
